import {
  buildMapToField,
  handleQueryResolve
} from '../utils'

export default function (RxNumber) {
  return this.query(`
  Select 
  MixGenericName,
  Rank,
  MixQuantity,
  MixFormCode,
  drugid
   from DBA.p_rpt_RxMixtureBreakdown(${RxNumber}, null, null)
  GROUP BY genericname,
  MixGenericName,
  Rank,
  MixQuantity,
  MixFormCode,
  drugid
      `).then(handleQueryResolve)
    .then(records => records.map(row => buildMapToField(row)))
}
